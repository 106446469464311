<template>
  <form role="form" @submit="onFormSubmit" name="update-client-settings">
    <div class="row">
      <div class="col-lg-12">
        <h3>{{ getLocalizedText(labels.title) }}</h3>
      </div>
    </div>
    <JSONTextArea
      v-bind:value.sync="form.clientSettings" 
      :label="getLocalizedText(labels.clientSettings)" 
      :placeholder="getLocalizedText(labels.clientSettings_placeholder)" 
      :labelAsColumn="true"
    />

    <button class="btn btn-primary ladda-button update" data-style="zoom-in" type="submit">{{ getLocalizedText(labels.updateButton) }}</button>
  </form>
</template>

<style>
  
</style>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as Ladda from 'ladda';

import TextField from '@fwk-client/components/panels/input/TextField.vue';
import Select from '@fwk-client/components/panels/input/Select.vue';
import SwitchCheck from '@fwk-client/components/panels/input/SwitchCheck.vue';
import JSONTextArea from '@root/src/client/components/panels/input/JSONTextArea.vue';

import { GenericModuleAdminInput, useGenericModuleAdmin } from "@root/src/client/composables/admin/useGenericModuleAdmin";

export default defineComponent({
  props: {
    module: {
        type: Object,
        required: true
      },
      genericModuleInput: {
        type: Object as PropType<GenericModuleAdminInput>,
        required: true
      }
  },
  components: {
    TextField,
    Select,
    SwitchCheck,
    JSONTextArea
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { getClientSettings, updateClientSettings } = useGenericModuleAdmin({
      ...props.genericModuleInput
    }, context);

    const availableLanguageCodes = ['fr','en'];

    const { module } = toRefs(props);

    /**
     * fileTypeConfigs:FileConfiguration[]
     */

    const clientSettings:Ref<any> = ref({});
    const form:Ref<any> = ref({
      clientSettings : {}
    });
    getClientSettings(module.value).then((result:any) => {
      clientSettings.value = result.clientSettings;
      updateFormFromClientSettings(result.clientSettings);
    });

    const labels = {
      "title": {
        "fr" : "Paramètres",
        "en" : "Settings"
      },
      "clientSettings": {
        "fr" : "Paramètres du client",
        "en" : "Client settings"
      },
      "clientSettings_placeholder": {
        "fr" : "Paramètres du client",
        "en" : "Client settings"
      },
      "updateButton" : {
        "fr" : "Sauvegarder",
        "en" : "Save"
      }
    }

    var laddaSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=update-client-settings] button.update' );
      laddaSubmit = Ladda.create(submitButton!);
    })

    const updateFormFromClientSettings = (settings:any) => {
      if(settings != undefined) {
        form.value.clientSettings = settings;
      }
    }

    const getSettingsFormForm = () => {
      let settings = form.value.clientSettings
      return settings;
    }

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      let settingsInput = getSettingsFormForm();
      laddaSubmit!.start();
      updateClientSettings(module.value, settingsInput).then((result:any) => {
        if(result.updated) {
          context.emit('module-updated', result.module);
        }
        laddaSubmit!.stop();
      })
    }

    return {
      form,
      labels,
      onFormSubmit
    }
  }  
})
</script>